<script>

  import eventBus from '@/utils/eventBus'

  import { employeeService } from '@/services'
  import { EMPLOYEE_SEARCH_CLEARED_EVENT, EMPLOYEE_SEARCH_STARTED_EVENT } from '@/utils/constants'

  export default {
    data: () => ({
      searchedEmployees: [],
      isSearchingEmployees: false,
      employeeSearchClearedEvent: EMPLOYEE_SEARCH_CLEARED_EVENT,
      employeeSearchStartedEvent: EMPLOYEE_SEARCH_STARTED_EVENT
    }),
    methods: {
      searchEmployees(searchString) {
        const self = this
        self.resetData()
        self.isSearchingEmployees = true
        self.closeMessage()
        self.searchedEmployees.splice(0, self.searchedEmployees.length)
        employeeService.searchEmployees(searchString).then((result) => {
          if (result.data && result.data.length) {
            self.searchedEmployees = [...result.data]
          }
          else {
            self.searchedEmployees = [{ employeeId: null, fullName: 'No results' }]
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          self.isSearchingEmployees = false
        })
      }
    },
    mounted() {
      eventBus.$on(this.employeeSearchClearedEvent, this.resetData)
      eventBus.$on(this.employeeSearchStartedEvent, this.searchEmployees)
    },
    beforeDestroy() {
      eventBus.$off(this.employeeSearchClearedEvent)
      eventBus.$off(this.employeeSearchStartedEvent)
    }
  }

</script>

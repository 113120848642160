import { format, addDays, addBusinessDays, compareAsc, isSameDay } from 'date-fns'

export const toShortDateString = (date) => {
  if (date) {
    return format(new Date(date), 'dd/MMM/yyyy')
  }
  return ''
}

export const toLongDateString = (date) => {
  if (date) {
    return format(new Date(date), 'iiii, MMMM do, yyyy')
  }
  return ''
}

export const fromServerDate = (date) => {
  if (date) {
    return format(new Date(date), 'yyyy-MM-dd')
  }
  return ''
}

export const isLeaveStartDateLessThanCurrentDate = (leaveStartDate) => {
  const date = new Date(leaveStartDate)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const comparisonResult = compareAsc(date, today)
  return comparisonResult === -1
}

export const getLeaveDates = (startDate, duration, ignoreNonBusinessDaysForEndDate = false, ignoreNonBusinessDaysForResumptionDate = false, publicHolidays = []) => {
  if (startDate) {
    startDate = new Date(startDate)
    startDate.setHours(0, 0, 0, 0)
    let dates = {
      startDate,
      numberOfHolidays: 0,
    }
    if (ignoreNonBusinessDaysForEndDate) {
      dates.endDate = addDays(startDate, duration - 1)
    }
    else {
      let endDateRecord = addNMABusinessDays(startDate, duration - 1, publicHolidays)
      dates.endDate = endDateRecord.theDate
      dates.numberOfHolidays += endDateRecord.numberOfHolidays
    }

    if (ignoreNonBusinessDaysForResumptionDate) {
      dates.resumptionDate = addDays(dates.endDate, 1)
    }
    else {
      let resumptionDateRecord = addNMABusinessDays(dates.endDate, 1, publicHolidays)
      dates.resumptionDate = resumptionDateRecord.theDate
      dates.numberOfHolidays += resumptionDateRecord.numberOfHolidays
    }
    dates.endDate = format(dates.endDate, 'yyyy-MM-dd')
    dates.resumptionDate = format(dates.resumptionDate, 'yyyy-MM-dd')

    if (!dates.numberOfHolidays) {
      dates.numberOfHolidays = null
    }
    return dates
  }
  return null
}

const addNMABusinessDays = (date, duration, publicHolidays) => {
  let count = 0
  let dates = {
    theDate: date,
    numberOfHolidays: 0
  }
  if (duration != 0) {
    do {
      dates.theDate = addBusinessDays(dates.theDate, 1)
      const dateIsPublicHoliday = (d) => isSameDay(d, dates.theDate)
      if (!publicHolidays.some(dateIsPublicHoliday)) {
        ++count
      }
      else {
        ++dates.numberOfHolidays
      }
    }
    while (count < duration) 
  }
  return dates
}

export const flattenHolidayDates = (startDate, numberOfDays) => {
  let theDate = new Date(Date.parse(startDate))
  var dates = [theDate]
  let i = 1
  while (i < numberOfDays) {
    theDate = addBusinessDays(theDate, 1, [])
    dates.push(theDate)
    i++
  }
  return dates
}

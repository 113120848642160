<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser
      }),
      currentUserCanRaiseRequestForOtherRegularEmployees() {
        return this.currentUser.isAdmin || this.currentUser.canAdministerBenefits
        //return this.currentUser.isAdmin || ((this.currentUser.userRights & Enums.UserRights.EditTeamData.value) === Enums.UserRights.EditTeamData.value) ||
        //  ((this.currentUser.userRights & Enums.UserRights.EditSectionData.value) === Enums.UserRights.EditSectionData.value) ||
        //  ((this.currentUser.userRights & Enums.UserRights.EditDivisionData.value) === Enums.UserRights.EditDivisionData.value) ||
        //  ((this.currentUser.userRights & Enums.UserRights.EditDepartmentData.value) === Enums.UserRights.EditDepartmentData.value) ||
        //  ((this.currentUser.userRights & Enums.UserRights.EditAllData.value) === Enums.UserRights.EditAllData.value)
      },
      currentUserCanRaiseRequestForExpatriates() {
        return this.currentUser.isExpatriateAdmin || this.currentUser.canAdministerBenefits || this.currentUser.isAdmin
      },
      currentUserCanRaiseRequestForOthers() {
        return this.currentUserCanRaiseRequestForOtherRegularEmployees || this.currentUserCanRaiseRequestForExpatriates
      }
    }
  }

</script>

<template>
  <div>
    <data-table :id='id' :headers='headers' :items='requestActions'>
      <template v-slot:item.date="{ item }">
        {{ item.date | toShortDateTimeString }}
      </template>
      <template v-slot:item.action="{ item }">
        {{ item.action | toRequestStatusDescription }}
      </template>
    </data-table>
  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>
  
  import { toRequestStatusDescription } from '@/filters/enum'
  import { toShortDateTimeString } from '@/filters'

  import DataTable from '@/components/ui/DataTable'

  export default {
    name: 'request-action-list',
    components: {
      DataTable
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'requestActionList'
      },
      requestActions: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      headers: [
        {
          name: 'userId',
          title: 'employee id'
        },
        {
          name: 'name',
          title: 'name'
        },
        {
          name: 'date',
          title: 'date'
        },
        {
          name: 'action',
          title: 'action'
        },
        {
          name: 'comment',
          title: 'comment'
        }
      ]
    }),
    filters: {
      toRequestStatusDescription,
      toShortDateTimeString
    }
  }

</script>

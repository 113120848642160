<script>
  import store from '@/store'
  import { mapState } from 'vuex'
  import { SCHEDULE_LEAVE_REQUEST, SAVE_EDITED_LEAVE_REQUEST } from '@/store/action-type'

  import { EMPLOYEE_SELECTED_EVENT, APPROVER_SEARCH_CLEARED_EVENT, APPROVER_SEARCH_STARTED_EVENT, APPROVER_SELECTED_EVENT } from '@/utils/constants'
  import eventBus from '@/utils/eventBus'

  import { fromServerDate } from '@/utils/date'
  import Enums from '@/utils/enums'
  import { ELeaveError } from '@/utils/errors'
  import ErrorMessages from '@/utils/errors/messages'


  import { employeeService } from '@/services'

  import AlertMixin from '@/mixins/AlertMixin'
  import ErrorMixin from '@/mixins/ErrorMixin'
  import EmployeeSearchMixin from '@/mixins/employeeSearch'

  import AppAlert from '@/components/ui/AppAlert'
  import AppModal from '@/components/ui/AppModal'
  import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
  import EmployeeInfoDisplay from '@/components/vacation/EmployeeInfoDisplay'
  import RequestActionList from '@/components/leaveRequests/RequestActionList'


  export default {
    components: {
      EmployeeAutocomplete,
      EmployeeInfoDisplay,
      AppAlert,
      AppModal,
      RequestActionList
    },
    mixins: [AlertMixin, ErrorMixin, EmployeeSearchMixin],
    data: () => ({
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        locationId: '',
        locationName: '',
        workScheduleId: '',
        workScheduleName: '',
        employeeTypeId: '',
        employeeTypeName: '',
        gender: '',
        supervisorName: '',
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
        isTemporalEmployee: false,
        isFieldEmployee: false,
        isOnForeignService: false
      },
      comment: null,
      employeeSelectedEvent: EMPLOYEE_SELECTED_EVENT,
      approverSearchClearedEvent: APPROVER_SEARCH_CLEARED_EVENT,
      approverSearchStartedEvent: APPROVER_SEARCH_STARTED_EVENT,
      approverSelectedEvent: APPROVER_SELECTED_EVENT,
      searchedApprovers: [],
      isSavingRequest: false,
      isCompleted: false,
      errorMessageIfRequestCannotBeRaisedForEmployee: null,
      pageIsFullyLoaded: false,
      //showNewRequestButton: false,
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        appSettings: state => state.applicationSettingsModule.appSettings

      }),
      leaveRequestCanBeRaisedForEmployee() {
        if (this.employeeInfo.employeeId) {
          if (!(this.employeeInfo.isRegularEmployee || this.employeeInfo.isExpatriateEmployee || this.employeeInfo.isTemporalEmployee)) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeType, this.request.leaveType)
            return false
          }
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return true
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
      isNewRequestPage() {
        return !(this.isEditPage || this.isViewPage || this.isReviewPage)
      },
      isEditable() {
        return !this.isViewPage && !this.isReviewPage
      },
      isEditPage() {
        return this.$route.name.startsWith('edit')
      },
      isViewPage() {
        return this.$route.name.startsWith('view')
      },
      isReviewPage() {
        return this.$route.name.startsWith('review')
      },
      disableFields() {
        return !this.isEditable || this.isSavingRequest /*|| this.isCancellingRequest*/
      }
    },
    watch: {
      errorMessageIfRequestCannotBeRaisedForEmployee(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.showErrorMessage(newValue)
          })
        }
        else {
          this.closeMessage()
        }
      }
    },
    methods: {
      resetData() {
        this.resetEmployeeData()
        this.resetBaseRequestData()
        if (this.resetOtherData) {
          this.resetOtherData()
        }
      },
      resetEmployeeData() {
        this.employeeInfo.employeeId = ''
        this.employeeInfo.employeeNo = ''
        this.employeeInfo.fullName = ''
        this.employeeInfo.employeeTypeId = ''
        this.employeeInfo.employeeTypeName = ''
        this.employeeInfo.workScheduleId = ''
        this.employeeInfo.workScheduleName = ''
        this.employeeInfo.locationId = ''
        this.employeeInfo.locationName = ''
        this.employeeInfo.gender = ''
        this.employeeInfo.supervisorName = ''
        this.employeeInfo.alternateSupervisorName = ''
        this.employeeInfo.isRegularEmployee = false
        this.employeeInfo.isExpatriateEmployee = false
        this.employeeInfo.isTemporalEmployee = false
        this.employeeInfo.isFieldEmployee = false
        this.employeeInfo.isOnForeignService = false
      },
      resetBaseRequestData() {
        this.request.id = null
        this.request.startDate = ''
        this.request.endDate = ''
        this.request.resumptionDate = ''
        this.request.duration = null
        this.request.remarks = ''
        this.request.comment = ''
        this.request.status = 0
        this.request.requestedById = ''
        this.request.requestDate = ''
        this.request.requestActions.splice(0, this.request.requestActions.length)
        this.request.version = []
      },
      setEmployeeData(employeeData) {
        this.employeeInfo.employeeId = employeeData.employeeId
        this.employeeInfo.employeeNo = employeeData.employeeNo
        this.employeeInfo.fullName = employeeData.fullName
        this.employeeInfo.employeeTypeId = employeeData.employeeTypeId
        this.employeeInfo.employeeTypeName = employeeData.employeeTypeName
        this.employeeInfo.workScheduleId = employeeData.workScheduleId
        this.employeeInfo.workScheduleName = employeeData.workScheduleName
        this.employeeInfo.locationId = employeeData.locationId
        this.employeeInfo.locationName = employeeData.locationName
        this.employeeInfo.gender = employeeData.gender
        this.employeeInfo.supervisorName = employeeData.supervisorName
        this.employeeInfo.alternateSupervisorName = employeeData.alternateSupervisorName
        this.employeeInfo.isFieldEmployee = employeeData.isFieldEmployee
        this.employeeInfo.isRegularEmployee = employeeData.isRegularEmployee
        this.employeeInfo.isExpatriateEmployee = employeeData.isExpatriateEmployee
        this.employeeInfo.isTemporalEmployee = employeeData.isTemporalEmployee
        this.employeeInfo.isOnForeignService = employeeData.isOnForeignService
      },
      setBaseRequestData(requestData) {
        this.request.id = requestData.id
        this.request.startDate = fromServerDate(requestData.startDate)
        this.request.duration = requestData.duration
        this.request.endDate = fromServerDate(requestData.endDate)
        this.request.resumptionDate = fromServerDate(requestData.resumptionDate)
        this.request.remarks = requestData.remarks
        this.request.version = requestData.version
        this.request.status = requestData.status
        this.request.intendedApproverId = requestData.intendedApproverId
        this.request.intendedApproverName = requestData.intendedApproverName
        this.request.requestedById = requestData.requestedById
        this.request.requestDate = fromServerDate(requestData.requestDate)
        this.request.employeeId = requestData.employeeId
        for (const action of requestData.requestActions) {
          this.request.requestActions.push(action)
        }
      },
      searchEmployees(searchString) {
        const self = this
        self.resetData()
        self.isSearchingEmployees = true
        self.closeMessage()
        self.searchedEmployees.splice(0, self.searchedEmployees.length)
        employeeService.searchEmployees(searchString).then((result) => {
          if (result.data && result.data.length) {
            //let filteredEmployees = []
            //if (self.currentUser.isAdmin) {
            //  filteredEmployees = result.data
            //}
            //self.searchedEmployees = [...filteredEmployees]
            self.searchedEmployees = [...result.data]
          }
          else {
            self.searchedEmployees = [{ employeeId: null, fullName: 'No results' }]
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          self.isSearchingEmployees = false
        })
      },
      clearApprover() {
        this.request.intendedApproverId = ''
        this.request.intendedApproverName = ''
      },
      searchApprovers(searchString) {
        const self = this
        self.closeMessage()
        self.clearApprover()
        self.searchedApprovers.splice(0, self.searchedApprovers.length)
        employeeService.searchEmployees(searchString).then((result) => {
          if (result.data && result.data.length) {
            self.searchedApprovers = [...result.data]
          }
        })
      },
      selectApprover(approver) {
        this.request.intendedApproverId = approver.employeeId
        this.request.intendedApproverName = approver.fullName
      },
      async validateRequest() {
        const approverField = document.getElementById('supv_search')
        if (approverField) {
          let invalidApprover = false
          if (!this.request.intendedApproverId) {
            approverField.setCustomValidity("No approver selected")
            invalidApprover = true
          }
          if (this.request.intendedApproverId.toLowerCase() == this.employeeInfo.employeeId.toLowerCase()) {
            approverField.setCustomValidity("You cannot approve your own request")
            invalidApprover = true
          }
          if (!invalidApprover) {
            approverField.setCustomValidity("")
          }
        }
        const requestForm = document.getElementById('leaveEntryForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            ".form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          return false
        }
        else {
          if (approverField) {
            const approverDirectReports = await employeeService.getEmployeeDirectReports(this.request.intendedApproverId)
            if (approverDirectReports && approverDirectReports.length) {
              return true
            }
            else {
              this.showErrorMessage('invalid approver. approver is not a supervisor')
              return false
            }
          }
          else {
            return true
          }
        }
      },
      submitRequest() {
        const self = this
        self.validateRequest().then((validationResult) => {
          if (validationResult) {
            self.closeMessage()
            self.isSavingRequest = true
            let data = { request: self.request }
            if (!self.request.id) {
              data.employeeId = self.employeeInfo.employeeId
            }
            else {
              data.comment = self.comment
            }
            let operation = self.request.id ? SAVE_EDITED_LEAVE_REQUEST : SCHEDULE_LEAVE_REQUEST
            store.dispatch(operation, data)
              .then(() => {
                self.requestState = Enums.RequestState.Submitted
                self.showSuccessMessage('request submitted successfully')
                self.isCompleted = true
                self.resetData()
              })
              .catch((error) => {
                if (error instanceof ELeaveError) {
                  self.showErrorMessage(error.message)
                }
                else {
                  self.showErrorMessage(error)
                }
              }).finally(() => {
                self.isSavingRequest = false
              })
          }

        })
      },
      startNewRequest() {
        this.isCompleted = false
        this.closeMessage()
        this.$router.go()
      },
      setEmployeeSearchEvents() {
        eventBus.$on(this.employeeSelectedEvent, this.fetchEmployeeData)
      },
      setApproverSearchEvents() {
        eventBus.$on(this.approverSearchClearedEvent, this.clearApprover)
        eventBus.$on(this.approverSearchStartedEvent, this.searchApprovers)
        eventBus.$on(this.approverSelectedEvent, this.selectApprover)
      },
      clearEmployeeSearchEvents() {
        eventBus.$off(this.employeeSelectedEvent)
      },
      clearApproverSearchEvents() {
        eventBus.$off(this.approverSearchClearedEvent)
        eventBus.$off(this.approverSearchStartedEvent)
        eventBus.$off(this.approverSelectedEvent)
      }
    },
    mounted() {
      this.setEmployeeSearchEvents()
      this.setApproverSearchEvents()
    },
    beforeDestroy() {
      this.clearEmployeeSearchEvents()
      this.clearApproverSearchEvents()
    }
  }

</script>
